// Body typography
body {
    font-family: effra, sans-serif;
    background: $hero-background;
}

.text-hero {
    color: $hero-color;
}

.box-title {
    font-family: effra-light, sans-serif;
    font-size: 40px;
    color: #525252;

    @include media-breakpoint-down(xs) {
        font-size: 26px;
    }
}

.star {
    width: 20px;
    height: 20px;
    background-image: url("../images/star-empty.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.love {
    width: 20px;
    height: 20px;
    background-image: url("../images/heart.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.plus {
    width: 20px;
    height: 20px;
    background-image: url("../images/plus-circle.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.bg-golden {
    background: $golden-color !important;
}

.load-more {
    width: 253px;
    height: 30px;
}

.clickable,
.clickable-same-tab {
    cursor: pointer;
}
