// Custom fonts
@font-face {
    font-family: 'effra';
    src: url('../fonts/Effra_Std_Rg.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'effra-light';
    src: url('../fonts/Effra_Std_Lt.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'effra-bold';
    src: url('../fonts/Effra_Std_Bd.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'effra-md';
    src: url('../fonts/Effra_Std_Md.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'effra-it';
    src: url('../fonts/Effra_Std_It.ttf');
    font-display: swap;
}
