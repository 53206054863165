.top-categories {

    h1 {
        @extend .box-title
    }

    img.main {
        @include media-breakpoint-up(xl) {
            max-width: 120px;
            max-height: 120px;
        }
    }

    p {
        color: $hero-color;
        font-size: 13px;
    }
}
