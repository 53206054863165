// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap Icons, Ref: https://icons.getbootstrap.com/
//@import '~bootstrap-icons/font/bootstrap-icons.css';

// Custom theme design, Ref: https://sulaco-tec.atlassian.net/browse/POR-163
@import "theme";

// Jquery UI autocomplete
//@import "~jquery-ui/themes/base/all.css"; // No need to import all functionality
@import "~jquery-ui/themes/base/core.css";
@import "~jquery-ui/themes/base/autocomplete.css";
@import "~jquery-ui/themes/base/menu.css";
@import "~jquery-ui/themes/base/theme.css";
