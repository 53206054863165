.search-content {
    h1 {
        @extend .box-title
    }

    .total-offer {
        color: $hero-color;
    }

    .offer {
        font-family: effra, sans-serif;
        font-size: 18px;
        color: #525252;

        .price-box {
            height: 49px;
            line-height: 49px;
        }

        .img-box {
            @extend .my-3;

            min-width: 105px;
            min-height: 105px;
        }

        img.main {
            max-width: 105px;
            max-height: 105px;
        }

        .bottom-content {
            height: 88px;
        }

        .offer-name {
            @extend .text-center, .mb-1;
        }

        .price-per-unit {
            @extend .d-block, .text-center, .mb-1;

            color: $hero-color;
            font-size: 14px;
        }

        .offer-shipping {
            @extend .d-block, .text-center, .mb-3;

            color: $hero-color;
            font-size: 13px;
        }
    }
}
