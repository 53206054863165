.hero-footer {
    ul {
        li {
            a {
                color: $hero-color;
                font-family: effra, sans-serif;
                font-size: 13px;
            }
        }
    }

    p {
        color: $hero-color;
        font-family: effra-bold, sans-serif;
        font-size: 13px;
    }

    @include media-breakpoint-down(xs) {
        ul, p {
            text-align: center;
        }

    }
}
