.promo-box-one {
    h1 {
        @extend .box-title
    }

    .content {
        font-size: 13px;

        h2 {
            font-family: effra, sans-serif;
            font-size: 25px;
        }

        p {
            font-family: effra, sans-serif;
            font-size: 13px;
            color: #F3F3F3;
        }
    }
}
