.top-promo-box {
    font-family: effra-light, sans-serif;
    background: #19232D;
    color: #D8D8D8;

    h1 {
        line-height: 127px;

        @include media-breakpoint-up(sm) {
            font-size: 30px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 40px;
        }
    }

    h2 {
        line-height: 100px;

        @include media-breakpoint-up(sm) {
            font-size: 18px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 20px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 30px;
        }
    }
}
