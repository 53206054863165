.promo-box-three {
    span {
        @extend .d-block;
        @extend .text-center;
        color: $hero-color;
        font-family: effra-light, sans-serif;
        font-size: 22px;

        @include media-breakpoint-up(sm) {
            font-size: 18px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 22px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 42px;
        }
    }


    img {
        @include media-breakpoint-down(xs) {
            min-height: 298px !important;
        }
    }

    button {
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }
}
