// Navbar
.hero-navbar {
    @include media-breakpoint-up(lg) {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 71px;
    }

    .home-button {
        @include media-breakpoint-up(lg) {
            font-size: 25px;
        }
    }

    .nav-width-lg {
        @include media-breakpoint-up(lg) {
            width: 33.33%;
        }
    }

    .search-box {
        input {
            //width: 253px;
            background-repeat: no-repeat;
            background-size: 15px 15px;
            background-position: 10px center;
            padding-left: 30px;
            background-image: url("../images/search-icon.png");
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        button {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }
}
