.hero-description {
    h1 {
        color: #525252;
        font-family: effra-light, sans-serif;
        font-size: 30px;

        @include media-breakpoint-up(lg) {
            font-size: 40px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }
    }

    p {
        color: $hero-color;
        font-family: effra-light, sans-serif;
        font-size: 13px;
    }
}
