.promo-box-two {
    h1 {
        @extend .box-title
    }

    .offer {
        .banner-image {
            @include media-breakpoint-down(xs) {
                max-height: 105px;
            }
        }

        .price-box {
            @include media-breakpoint-down(xs) {
                width: 81px;
                height: 32px;
                line-height: 32px;
                font-size: 16px;
            }
        }

        .product-image {
            @include media-breakpoint-down(xs) {
                max-height: 73px;
            }
        }

        .product-name {
            color: $hero-color;
            font-size: 26px;
            width: 60%;

            @include media-breakpoint-up(sm) {
                font-size: 18px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 26px;
            }
        }
    }

    .bottom-fixed {
        z-index: 1;
        bottom: 0;
        opacity: .8;

        span {
            @extend .d-block;
            color: $hero-color;
            font-family: effra-light, sans-serif;

            @include media-breakpoint-up(sm) {
                font-size: 14px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 20px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 26px;
            }
        }

        .bg-dark {
            opacity: 1;
            background: black !important;
        }
    }
}
