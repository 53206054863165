.smartlinks {
  color: $golden-color;
}

.smartlink-show-more {
    color: $golden-color;
    position: absolute;
    right: 20px;
    bottom: -35px;
}
