.filter {
    @extend .position-relative;

    min-height: 42px;

    #navbarToggleExternalContent {
        @extend .border;

        border-width: 2px;
    }

    .position-absolute {
        z-index: 99999;
    }
}
