.offer {
    font-size: 13px;
    cursor: pointer;

    .shop-box {
        padding-top: 5px;
        padding-left: 5px;
        font-size: 14px;

        .img-shop-box {
            min-width: 50px;
            min-height: 50px;
            max-width: 100px;
            max-height: 50px;
        }
    }

    .price-box {
        background: #B19E6A;
        color: #ffffff;
        font-size: 25px;
        min-width: 104px;
        line-height: 54px;
    }

    .img-box {
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;
    }

    .feature {
        min-height: 56px;
        line-height: 20px;

        .border-top {
            border-color: #F3F3F3;
        }
    }

    @include media-breakpoint-up(sm) {
        .img-box {
            min-width: 110px;
            max-width: 110px;
            min-height: 110px;
            max-height: 110px;
        }
    }

    @include media-breakpoint-up(lg) {
        .img-box {
            min-width: 208px;
            max-width: 208px;
            min-height: 208px;
            max-height: 208px;
        }
    }
}
