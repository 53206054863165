.top-products {
    margin-top: 36px; // 227+71 = 298, 298−334 = 36
    color: #ABABAB;

    // Tab text color
    a {
        color: #ABABAB;
    }

    // Omit active tab border
    .nav-tabs {
        .nav-link {
            &.active {
                border: none;
                background: none;
            }
        }
    }

    // Remove shipping from top products
    .offer {
        .offer-shipping {
            @extend .d-none;
        }
    }
}
