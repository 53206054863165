.brand {
    @extend .offer;
}

//Mocha partner search brand box
//https://sulaco-tec.atlassian.net/browse/POR-148
.top-brands-title {
    line-height: 83px;
}

.top-brand-box-border {
    @include media-breakpoint-down(lg) {
        border: 1px solid #dee2e6 !important;
    }
}
