$hero-background: #F3F3F3;
$hero-color: #ABABAB;
$golden-color: #B19E6A;

// Container
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1251px
);
